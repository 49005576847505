<template>
    <div class="rank-content">
        <ul class="table-header">
            <li v-for="item in headList"
                :key="item.filed"
                :style="{minWidth:item.width}">
                {{item.name}}
            </li>
        </ul>
        <div class="scroll">

            <div class="main-content">
                <ul class="tech-list" v-if="!!clockRanking && clockRanking.length > 3">
                    <li class="tech-item"
                        v-for="(item,index) in clockRanking"
                        v-if="index>2"
                        :key="index">
                        <div class="rank-box">
                            {{index+1}}
                        </div>
                        <div class="avatar-box">
                            <i class="avatar"><img :src="item.avatar" :onerror="defaultAvatar"></i>
                            <p class="staff-no">{{item.staff_no}}</p>
                        </div>
                        <div class="clock-box">
                            {{item.total_clock|valueKbits}}
                        </div>
                        <div class="clock-box">
                           {{item.dot_clock|valueKbits}}
                        </div>
                        <div class="clock-box">
                           {{item.add_clock|valueKbits}}
                        </div>
                    </li>
                </ul>
                <daqi-copyright></daqi-copyright>
                <div style="height: 60px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
	import DaqiCopyright from "../../../components/daqi-copyright";
	export default {
		name:"top-three",
		components:{DaqiCopyright},
		props:{
			clockRanking:Array
		},
		data(){
			return {
				totalClockSum:1,
				dotClockSum:1,
				headList:[{name:"排名",width:""},{name:"工号"},{name:"总钟"},{name:"点钟"},{name:"加钟"}]
			}
		},
		watch:{
			clockRanking:{
				handler(newVal){
					if(! newVal.length){
						return
					}
					this.getTotalClock()
				},
				deep:true,
			}
		},
		computed:{
			totalClockRate(){
				let {totalClockSum,dotClockSum} = this
				return function(clock,type){
					let totalClock = type === 'total'?totalClockSum:dotClockSum
                    let rate = totalClock>0?(clock / totalClock) * 100 + '%':0
					return rate
				}
			},
			defaultAvatar(){
				return 'this.src="' + require('@/assets/images/avatar-default.png') + '";this.className="default-avatar"'
			},
        },
        filters:{
                valueKbits:(value) => {
                  let result="";
                  if(!value || value == '.00'||value == '0.00'){
                    result = "0.00"
                  }else{
                    value = parseFloat(value)
                    value =value.toFixed(2)
                    result=(value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
                  }
                  return result
                },
        },
		methods:{
			/**
			 *  计算所有技师钟数之和
			 */
			getTotalClock(){
				let {clockRanking} = this
				let totalClockSum = 0
				let dotClockSum = 0
				for(let item of clockRanking){
					totalClockSum += parseFloat(item.total_clock)
					dotClockSum += parseFloat(item.dot_clock)
				}
				this.dotClockSum = dotClockSum
				this.totalClockSum = totalClockSum
			}
		},
		created(){
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";
    .rank-content{
        flex:1;
        min-height:0;
        overflow:hidden;
        width:100vw;
        padding:0px 28px;
        background:#fff;
        display: flex;
        flex-direction:column;
    }
    .table-header{
        background:#f1f1f1;
        @extend %flex-center-row;
        color:#6d6b6b;
        font-size:24px;
        line-height:58px;
        height:58px;
        margin:26px 0 0;
        border-radius:5px;
        li{
            flex:5;
            text-align:center;
            white-space:nowrap;
        }
        li:nth-child(2){
          flex:8 !important;
          text-align: left;
        }
    }
    .scroll{
        /* height:calc(100% - 300px); */
        /* height:-moz-calc(100% - 80px);
        height:-webkit-calc(100% - 80px); */
        /* flex:1; */
        height:49vh;
        overflow-x:hidden;
        overflow-y:auto;
    }
    .main-content{
        min-height:calc(100% - 101px);
    }
    .tech-list{
    }

    .tech-item{
        display:flex;
        align-items:center;
        height:100px;
        background:#FFFFFF;
        border-bottom: #f1f1f1 1px solid;
        text-align:center;

        div{
            flex:5;
            color:#333;
            font-size:28px;
        }
    }
    .staff-no{
        flex:1;
        text-align: left;
        padding-left: 5px;
    }
    .rank-box{
        // flex:1;
        color:#999 !important;
    }
    .avatar-box{
        display:flex;
        flex:8 !important;

        align-items:center;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        i{
            display:inline-block;
            background:$high-light-color;
            border-radius:50%;
            width:60px;
            height:60px;
            img{
                width:60px;
                height:60px;
                object-fit:cover;
                border-radius:50%;
                // margin-right: 10px;
            }

            .default-avatar{
                width:40px;
                height:40px;
                margin:10px;
            }
        }

    }


</style>
